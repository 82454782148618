import * as React from "react"
import Layout from "../../components/layout";
import {graphql} from 'gatsby'
// markup
const IndexPage = ({params, data}) => {
    return (
        <Layout pageTitle="Home">
            {params.name}
            {JSON.stringify(data)}
        </Layout>
    )
};

export const query = graphql`
{
    topics:allTopicsJson{
        nodes{
        id
        }
    }
}
`;

export default IndexPage
